* {
  font-family: 'Montserrat', sans-serif;
}


.loadingText:after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    font-size: 22px;
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(255, 255, 255);
    font-size: 22px;

    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    font-size: 22px;

    text-shadow:
    
      .25em 0 0 rgb(209, 119, 45),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    font-size: 22px;

    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 10px #00aaff;
    border: 2px solid #00aaff;
  }

  50% {
    box-shadow: 0 0 20px #00aaff;
    border: 3px solid #00aaff;
  }

  100% {
    box-shadow: 0 0 10px #00aaff;
    border: 2px solid #00aaff;
  }
}

.onPage {
  color: rgb(255, 255, 255);
  text-decoration: underline
}

.special4:hover {
  color: rgb(238, 181, 23);
  cursor: pointer;
}

.stakeMain {
  overflow: hidden;
}

.light {
  overflow: hidden;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,600;0,800;0,900;1,500;1,600;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Exo:wght@900&display=swap');

.special5 {
  color: white;
  text-transform: capitalize;
}


.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #d6f7ac;
  border-color: #d6f7ac transparent #a4d4fc transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.formMain2New {
  transform: translate(-50%, -50%);
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  top: 50%;
  left: 50%;
  z-index: 100000000000;
  background-color: rgba(0, 0, 0, 0.986);
}

.formMain-2New {
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.856);
  position: fixed;
  width: fit-content;
  height: fit-content;
  top: 50%;
  left: 50%;
  z-index: 1;
  overflow: scroll;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  background-color: rgb(0, 0, 0);
  animation: popupAnimation 0.5s ease forwards;
  border-radius: 10px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.special {
  text-decoration: underline;
  color: #ffffff;
  cursor: pointer;
}

.special2 {
  color: #b8f7c2;
}

.special3 {
  color: #fd4c4c;
}

.back {
  width: 35px;
  height: 35px;
  bottom: 30px;
  position: sticky;
  margin-left: 20px;
  cursor: pointer;
}

.lds-circle {
  display: inline-block;
  transform: translateZ(1px);
}

.lds-circle>div {
  display: inline-block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  background: #fff;
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes lds-circle {

  0%,
  100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }

  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }

  100% {
    transform: rotateY(3600deg);
  }
}

.page-loader {
  width: 100%;
  height: 100vh;
  background: #242424;
  z-index: 5000000000000000;
  position: fixed;
}

.loading-area {
  display: grid;
  place-items: center;
  height: 100vh;
}

.containerLoad {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.h1Load {
  font-size: 1.5rem;
  color: #00ff2a;
}

.ring {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.ring::before {
  content: '';
  width: 200px;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  animation: ring 2s linear infinite;
}

@keyframes ring {
  0% {
    transform: rotate(0deg);
    box-shadow: 0px 5px 5px #008afa;
  }

  25% {
    transform: rotate(90deg);
    box-shadow: 0px 5px 5px #02b7ff;
  }

  50% {
    transform: rotate(180deg);
    box-shadow: 0px 5px 5px #0151ff;
  }

  75% {
    transform: rotate(270deg);
    box-shadow: 0px 5px 5px #367de7;
  }

  100% {
    transform: rotate(360deg);
    box-shadow: 0px 5px 5px #008afa;
  }
}

::-webkit-scrollbar {
  width: 13px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fb2ef9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b600b3;
}

.title:hover {
  cursor: pointer;
}

@media screen and (max-width: 499px) {
  .connect2btns{
    display: flex;
  }
  
    .mm {
      width: 30px;
      height: 30px;
    }
  
    .connect2-2-Mob{
      width: 30px;
      height: 30px;
    }
  
    .connect2-2-Mob {
      margin-top: auto;
      margin-bottom: auto;
      justify-content: center;
      display: flex;
      background-color: #3d3d3d67;
      width: 100px;
      border-radius: 50px;
      border: white 1px solid;
      margin-left: 20px;
      cursor: pointer;
      color: white;
      margin-top: auto;
      margin-bottom: auto;
      justify-content: center;
      
     }

     .connect2-2-Mob-2 {
      margin-top: auto;
      margin-bottom: auto;
      justify-content: center;
      display: flex;
      background-color: #3d3d3d67;
      width: 100px;
      border-radius: 50px;
      border: white 1px solid;
      margin-left: 20px;
      cursor: pointer;
      color: white;
      margin-top: auto;
      margin-bottom: auto;
      justify-content: center;
      margin-top: 2px;
     }
  
 
  .already {
    color: rgb(216, 255, 107);
    text-align: center;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .loadingText{
    font-size: 16px;
    color: rgb(255, 255, 255);
    text-align: center;
  }
  .notificationMain {
    animation: glow 2s infinite;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
    background-color: #070a08af;
    width: fit-content;
    padding: 5px;

    padding-left: 20px;
    padding-right: 20px;
    border-radius: 50px;

    border: 2px solid #00aaff;
    /* Set your border style and color */
    box-shadow: 0 0 10px #00aaff;
  }

  .special4 {
    font-size: 14px;
    color: rgb(252, 208, 89);
    text-decoration: underline;
  }

  .closeNote {
    position: absolute;
    right: 0;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 50px;
    height: fit-content;
    cursor: pointer;
    border: none;
    transition: transform .2s ease-in-out;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: #e15764;
  }


  .success-message {
    color: white;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 22px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 16px;
    margin-bottom: 0;
  }

  .check {
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  .alreadyMain {
    background-color: rgba(0, 0, 0, 0.445);
    margin-top: 2%;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100vw;
    height: fit-content;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMainImgSet {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-items: center;
    display: flex;
  }

  .navbar {
    width: 40px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    align-items: center;

  }

  #fontSize2 {
    font-size: 16px;
    color: white;
    text-align: left;
  }

  #fontSize2:hover {
    color: #818181;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: rgba(255, 255, 255, 0.233) 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgba(49, 49, 49, 0.603) solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .allWrap {
    background: rgb(70, 63, 101);
    background: radial-gradient(circle, rgba(70, 63, 101, 1) 0%, rgba(18, 19, 38, 1) 46%);
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /*contain: content;*/
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/bg2.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding-top: 10vh;
    padding-bottom: 10vh;
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    display: flex;
  }

  .footerImg {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: transform .2s;
  }

  .footerImg img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    height: 5vh;
  }

  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  .connect div:hover {
    transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
    width: 33.33%;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 18px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

  }

  #fontSize-2 {
    font-size: 25px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
  }

  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
      #009218;
    /* background color */
    color: #0000;
    border: none;
    transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
    text-shadow: calc(var(--_i, -1) * 0.08em) -.01em 0 var(--c),
      calc(var(--_i, -1) * -0.08em) .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }

  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }

  .button-92 {
    font-weight: bold;
    font-size: 1.8rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 25px;
    display: none;
  }

  .logoUp {
    width: 40px;
    height: 40px;
    margin-top: -5px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: none;
  }

  .connect2-2-Mob {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .bg1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/lightning.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    height: max-content;
    animation: left-to-right 1s ease-out forwards;
    opacity: 0;

    /* Set opacity to 0 to start with a fade-in effect */
  }

  @keyframes left-to-right {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }


  .bg2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/fire.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    animation: right-to-left 1s ease-out forwards;
    opacity: 0;
  }

  @keyframes right-to-left {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }



  .btn1 {
    width: fit-content;
    font-size: 22px;
    background: rgb(33, 150, 0);
    background: linear-gradient(93deg, rgba(33, 150, 0, 1) 0%, rgba(22, 254, 229, 1) 100%);
    border: none;
    color: white;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
  }

  .btn2 {
    width: fit-content;
    font-size: 22px;
    color: white;
    background: rgb(255, 86, 0);
    background: linear-gradient(94deg, rgba(255, 86, 0, 1) 0%, rgba(33, 150, 0, 1) 100%);
    border: none;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
  }

  .mainDiv {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/mainBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    display: flex;
    flex-flow: column nowrap;
  }

  .navBarStake {
    width: 100%;
    display: flex;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    height: max-content;
    display: flex;
  }

  .formMain {
    display: none;
  }

  .formMain-2 {
    display: none;
    margin-bottom: 5%;
    margin-top: 5%;
  }

  .formMain2 {
    display: none;
    margin-bottom: 5%;
    margin-top: 5%;
    background-color: rgba(0, 0, 0, 0.534);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
  }

  .formMain2-2 {
    display: none;
    margin-bottom: 5%;
    background-color: rgba(0, 0, 0, 0.534);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
  }

  .battleBtn {
    display: none;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    background-color: black;
    color: white;
    border-radius: 20px;
    border: 2px white solid;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: 1px ease-in-out;
  }

  .battleBtn:hover {
    background-color: #000000b0;
  }

  .battleBtn-2 {
    display: none;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    background-color: black;
    color: white;
    border-radius: 20px;
    border: 2px white solid;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: 1px ease-in-out;
  }

  .battleBtn-2:hover {
    background-color: #000000b0;
  }


  .battle {
    width: 40px;
    height: 40px;
    margin-left: 10px;
  }

  .relicT {
    color: white;
    font-size: 18px;
  }

  .inputSection {
    color: white;
  }

  input {
    margin-top: 5%;
    margin-bottom: 5%;
    background-color: #3b3b3b5b;
    border: none;
    color: white;
    padding: 10px;
    border: 2px solid white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .inputSection {
    background-color: #009218;
    width: fit-content;
    border: none;
    font-size: 20px;
    box-shadow: -3px 3px #006811;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 3%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 55%;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .images_show {
    width: 90%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    font-size: 15px;
  }

  .tS {
    text-transform: capitalize;
    text-align: center;
    color: white;
    font-size: 16px;
    border: none;
    color: white;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
    backdrop-filter: blur(5px);
    background: rgb(23, 208, 10);
    background: linear-gradient(317deg, rgba(23, 208, 10, 0.23771227240896353) 0%, rgba(253, 187, 45, 0.18168986344537819) 100%);
  }

  .notification {
    text-align: center;
    color: rgb(245, 209, 154);
    border: none;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
    backdrop-filter: blur(5px);
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: -150px;
  }

  .notification1 {
    font-size: 16px;
  }


  .buy {
    text-align: center;
    color: rgb(255, 255, 255);
    text-decoration: underline;
    font-size: 16px;
    cursor: pointer;
    background-color: rgba(255, 228, 196, 0);
  }

  .buy:hover {
    color: #b9b9b9;
  }

  .progress-bar {
    position: relative;
    width: 90%;
    height: 30px;
    border-radius: 100px;
    background-color: #ddd;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }

  .team1-progress {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0%;
    background: rgb(0, 77, 255);
    background: linear-gradient(106deg, rgba(0, 77, 255, 1) 0%, rgba(76, 130, 255, 1) 100%);
    border-radius: 10px 0 0 10px;
  }

  .team2-progress {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 0%;
    background: rgb(246, 121, 97);
    background: linear-gradient(106deg, rgba(246, 121, 97, 1) 0%, rgba(200, 33, 0, 1) 100%);
    border-radius: 0 10px 10px 0;
  }

  .teams {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10vh;
    flex-flow: column nowrap;
  }

  .teams2 {
    background-color: rgba(0, 0, 0, 0.582);
    padding: 5px;
    flex-flow: column nowrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    background: rgb(28, 94, 242);
    background: linear-gradient(106deg, rgba(28, 94, 242, 0.0761353567744909) 0%, rgba(218, 117, 22, 0.0648994017183111) 100%);
    backdrop-filter: blur(5px);
    border: 1px rgba(255, 255, 255, 0.37) solid;
  }

  .t1 {
    color: white;
    font-size: 30px;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgb(0, 102, 255), 0 -10px 20px rgb(0, 60, 150), 0 -18px 40px rgb(0, 127, 150);
  }

  .teams img {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .t2 {
    color: white;
    font-size: 30px;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #ee5650, 0 -10px 20px #e27a24, 0 -18px 40px #9c5113;
  }


  .vs {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 80px;
    color: #FFFFFF;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }

  .time1 {
    font-size: 22px;
    text-align: center;
    color: rgb(236, 236, 236);
    text-shadow: 0 0 10px #FFFFFF;
    letter-spacing: 5px;
  }

  .time2 {
    font-size: 60px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .bell {
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }


  .cliamRewards {
    font-size: 20px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background: rgb(0, 198, 34);
    background: linear-gradient(4deg, rgba(0, 198, 34, 1) 0%, rgba(43, 147, 11, 1) 100%);
    border: none;
    color: white;
    padding: 10px;
    box-shadow: -5px 5px rgb(33, 83, 0);
  }

  .cliamRewards:hover {
    background: rgb(43, 147, 11);
    background: linear-gradient(4deg, rgba(43, 147, 11, 1) 0%, rgba(0, 198, 34, 1) 100%);
  }

  .congratz {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 22px;
    text-align: center;
    color: rgb(236, 236, 236);
    letter-spacing: 3px;
    text-shadow: 0 0 10px #FFFFFF;
    margin-bottom: 20px;
  }

  .popper {
    width: 30px;
    height: 30px;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
 
.connect2btns{
  display: flex;
}

.connect2-2-Mob-2 {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  background-color: #3d3d3d67;
  width: 100px;
  border-radius: 50px;
  border: white 1px solid;
  margin-left: 20px;
  cursor: pointer;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-top: 2px;
 }
  .mm {
    width: 30px;
    height: 30px;
  }

  .connect2-2-Mob{
    width: 30px;
    height: 30px;
  }

  .connect2-2-Mob {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #3d3d3d67;
    width: 100px;
    border-radius: 50px;
    border: white 1px solid;
    margin-left: 20px;
    cursor: pointer;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

   }

 
 
  .already {
    color: rgb(216, 255, 107);
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .loadingText{
    font-size: 16px;
    color: rgb(255, 255, 255);
    text-align: center;
  }
  .notificationMain {
    animation: glow 2s infinite;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
    background-color: #070a08af;
    width: fit-content;
    padding: 5px;

    padding-left: 20px;
    padding-right: 20px;
    border-radius: 50px;

    border: 2px solid #00aaff;
    /* Set your border style and color */
    box-shadow: 0 0 10px #00aaff;
  }

  .special4 {
    font-size: 15px;
    color: rgb(252, 208, 89);
    text-decoration: underline;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 5%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
    transition: transform .2s ease-in-out;
  }


  .closeNote:hover,
  .closeNote:focus {
    color: #e15764;
  }


  .success-message {
    color: #ffffff;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }

  .check {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  .alreadyMain {
    background-color: rgba(0, 0, 0, 0.445);
    margin-top: 2%;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100vw;
    height: fit-content;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMainImgSet {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-items: center;
    display: flex;
  }

  .navbar {
    width: 40px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    align-items: center;

  }

  #fontSize2 {
    font-size: 16px;
    color: white;
    text-align: left;
  }

  #fontSize2:hover {
    color: #818181;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: rgba(255, 255, 255, 0.233) 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgba(49, 49, 49, 0.603) solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .allWrap {
    background: rgb(70, 63, 101);
    background: radial-gradient(circle, rgba(70, 63, 101, 1) 0%, rgba(18, 19, 38, 1) 46%);
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /*contain: content;*/
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/bg2.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding-top: 10vh;
    padding-bottom: 10vh;
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    display: flex;
  }

  .footerImg {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: transform .2s;
  }

  .footerImg img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    height: 5vh;
  }

  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  .connect div:hover {
    transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
    width: 33.33%;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 18px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

  }

  #fontSize-2 {
    font-size: 25px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
  }

  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
      #009218;
    /* background color */
    color: #0000;
    border: none;
    transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
    text-shadow: calc(var(--_i, -1) * 0.08em) -.01em 0 var(--c),
      calc(var(--_i, -1) * -0.08em) .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }

  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }

  .button-92 {
    font-weight: bold;
    font-size: 1.8rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 25px;
    display: none;
  }

  .logoUp {
    width: 40px;
    height: 40px;
    margin-top: -5px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: none;
  }

  .connect2-2-Mob {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .bg1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/lightning.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    height: max-content;
    animation: left-to-right 1s ease-out forwards;
    opacity: 0;

    /* Set opacity to 0 to start with a fade-in effect */
  }

  @keyframes left-to-right {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }


  .bg2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/fire.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    height: max-content;
    animation: right-to-left 1s ease-out forwards;
    opacity: 0;
  }

  @keyframes right-to-left {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }




  .btn1 {
    width: fit-content;
    font-size: 25px;
    background: rgb(33, 150, 0);
    background: linear-gradient(93deg, rgba(33, 150, 0, 1) 0%, rgba(22, 254, 229, 1) 100%);
    border: none;
    color: white;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
  }

  .btn2 {
    width: fit-content;
    font-size: 25px;
    color: white;
    background: rgb(255, 86, 0);
    background: linear-gradient(94deg, rgba(255, 86, 0, 1) 0%, rgba(33, 150, 0, 1) 100%);
    border: none;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
  }

  .btn1:hover {
    transform: scale(1.2);
  }

  .btn2:hover {
    transform: scale(1.2);
  }

  .mainDiv {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/mainBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    display: flex;
    flex-flow: column nowrap;
  }

  .navBarStake {
    width: 100%;
    display: flex;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    display: flex;
  }

  .formMain {
    display: none;
  }

  .formMain-2 {
    display: none;
    margin-bottom: 5%;
    margin-top: 5%;
  }

  .formMain2 {
    display: none;
    margin-bottom: 5%;
    margin-top: 5%;
    background-color: rgba(0, 0, 0, 0.534);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
  }

  .formMain2-2 {
    display: none;
    margin-bottom: 5%;
    background-color: rgba(0, 0, 0, 0.534);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
  }

  .battleBtn {
    display: none;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    font-size: 25px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    border: none;
    background-color: black;
    color: white;
    border-radius: 20px;
    border: 2px white solid;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: 1px ease-in-out;
  }

  .battleBtn:hover {
    background-color: #000000b0;
  }

  .battleBtn-2 {
    display: none;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    font-size: 25px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    border: none;
    background-color: black;
    color: white;
    border-radius: 20px;
    border: 2px white solid;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: 1px ease-in-out;
  }

  .battleBtn-2:hover {
    background-color: #000000b0;
  }


  .battle {
    width: 40px;
    height: 40px;
    margin-left: 10px;
  }

  .relicT {
    color: white;
    font-size: 25px;
  }

  .inputSection {
    color: white;
  }

  input {
    margin-top: 5%;
    margin-bottom: 5%;
    background-color: #3b3b3b5b;
    border: none;
    color: white;
    padding: 10px;
    border: 2px solid white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .inputSection {
    background-color: #009218;
    width: fit-content;
    border: none;
    font-size: 23px;
    box-shadow: -3px 3px #006811;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 3%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40%;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .images_show {
    width: 90%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .tS {
    text-transform: capitalize;
    text-align: center;
    color: white;
    font-size: 20px;
    border: none;
    color: white;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
    backdrop-filter: blur(5px);
    background: rgb(23, 208, 10);
    background: linear-gradient(317deg, rgba(23, 208, 10, 0.23771227240896353) 0%, rgba(253, 187, 45, 0.18168986344537819) 100%);
  }

  .notification {
    text-align: center;
    color: rgb(245, 209, 154);
    border: none;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
    backdrop-filter: blur(5px);
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .notification1 {
    font-size: 20px;
  }


  .buy {
    text-align: center;
    color: rgb(255, 255, 255);
    text-decoration: underline;
    font-size: 18px;
    cursor: pointer;
    background-color: rgba(255, 228, 196, 0);
  }

  .buy:hover {
    color: #b9b9b9;
  }

  .progress-bar {
    position: relative;
    width: 90%;
    height: 30px;
    border-radius: 100px;
    background-color: #ddd;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }

  .team1-progress {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0%;
    background: rgb(0, 77, 255);
    background: linear-gradient(106deg, rgba(0, 77, 255, 1) 0%, rgba(76, 130, 255, 1) 100%);
    border-radius: 10px 0 0 10px;
  }

  .team2-progress {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 0%;
    background: rgb(246, 121, 97);
    background: linear-gradient(106deg, rgba(246, 121, 97, 1) 0%, rgba(200, 33, 0, 1) 100%);
    border-radius: 0 10px 10px 0;
  }

  .teams {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10vh;
    flex-flow: column nowrap;
  }

  .teams2 {
    background-color: rgba(0, 0, 0, 0.582);
    padding: 5px;
    flex-flow: column nowrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    background: rgb(28, 94, 242);
    background: linear-gradient(106deg, rgba(28, 94, 242, 0.0761353567744909) 0%, rgba(218, 117, 22, 0.0648994017183111) 100%);
    backdrop-filter: blur(5px);
    border: 1px rgba(255, 255, 255, 0.37) solid;
  }

  .t1 {
    color: white;
    font-size: 30px;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgb(0, 102, 255), 0 -10px 20px rgb(0, 60, 150), 0 -18px 40px rgb(0, 127, 150);
  }

  .teams img {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .t2 {
    color: white;
    font-size: 30px;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #ee5650, 0 -10px 20px #e27a24, 0 -18px 40px #9c5113;
  }


  .vs {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 80px;
    color: #FFFFFF;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }

  .time1 {
    font-size: 22px;
    text-align: center;
    color: rgb(236, 236, 236);
    text-shadow: 0 0 10px #FFFFFF;
    letter-spacing: 5px;
  }

  .time2 {
    font-size: 60px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .bell {
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }

  .cliamRewards {
    font-size: 22px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background: rgb(0, 198, 34);
    background: linear-gradient(4deg, rgba(0, 198, 34, 1) 0%, rgba(43, 147, 11, 1) 100%);
    border: none;
    color: white;
    padding: 10px;
    box-shadow: -5px 5px rgb(33, 83, 0);
  }

  .cliamRewards:hover {
    background: rgb(43, 147, 11);
    background: linear-gradient(4deg, rgba(43, 147, 11, 1) 0%, rgba(0, 198, 34, 1) 100%);
  }

  .congratz {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 30px;
    text-align: center;
    color: rgb(236, 236, 236);
    letter-spacing: 3px;
    text-shadow: 0 0 10px #FFFFFF;
    margin-bottom: 20px;
  }

  .popper {
    width: 30px;
    height: 30px;
  }

  .blur {
    backdrop-filter: blur(5px);
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  
  .connect2btns{
    display: flex;
  }
  
  .connect2-2-Mob-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #3d3d3d67;
    width: 100px;
    border-radius: 50px;
    border: white 1px solid;
    margin-left: 20px;
    cursor: pointer;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-top: 2px;
   }
    .mm {
      width: 30px;
      height: 30px;
    }
  
    .connect2-2-Mob{
      width: 30px;
      height: 30px;
    }
  
    .connect2-2-Mob {
      margin-top: auto;
      margin-bottom: auto;
      justify-content: center;
      display: flex;
      background-color: #3d3d3d67;
      width: 100px;
      border-radius: 50px;
      border: white 1px solid;
      margin-left: 20px;
      cursor: pointer;
      color: white;
      margin-top: auto;
      margin-bottom: auto;
      justify-content: center;
  
     }
    s
  
  .already {
    color: rgb(216, 255, 107);
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .loadingText{
    font-size: 16px;
    color: rgb(255, 255, 255);
    text-align: center;
  }
  .notificationMain {
    animation: glow 2s infinite;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
    background-color: #070a08af;
    width: fit-content;
    padding: 5px;

    padding-left: 20px;
    padding-right: 20px;
    border-radius: 50px;

    border: 2px solid #00aaff;
    /* Set your border style and color */
    box-shadow: 0 0 10px #00aaff;
  }

  .special4 {
    font-size: 16px;
    color: rgb(252, 208, 89);
    text-decoration: underline;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 5%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
    transition: transform .2s ease-in-out;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: #e15764;
  }

  .success-message {
    color: #ffffff;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .check {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  .alreadyMain {
    background-color: rgba(0, 0, 0, 0.445);
    margin-top: 2%;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100vw;
    height: fit-content;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMainImgSet {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-items: center;
    display: flex;
  }

  .navbar {
    width: 40px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    align-items: center;

  }

  #fontSize2 {
    font-size: 16px;
    color: white;
    text-align: left;
  }

  #fontSize2:hover {
    color: #818181;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: rgba(255, 255, 255, 0.233) 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgba(49, 49, 49, 0.603) solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .allWrap {
    background: rgb(70, 63, 101);
    background: radial-gradient(circle, rgba(70, 63, 101, 1) 0%, rgba(18, 19, 38, 1) 46%);
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /*contain: content;*/
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/bg2.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding-top: 10vh;
    padding-bottom: 10vh;
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    display: flex;
  }

  .footerImg {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: transform .2s;
  }

  .footerImg img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    height: 5vh;
  }

  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  .connect div:hover {
    transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
    width: 33.33%;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 18px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

  }

  #fontSize-2 {
    font-size: 25px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
  }

  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
      #009218;
    /* background color */
    color: #0000;
    border: none;
    transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
    text-shadow: calc(var(--_i, -1) * 0.08em) -.01em 0 var(--c),
      calc(var(--_i, -1) * -0.08em) .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }

  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }

  .button-92 {
    font-weight: bold;
    font-size: 1.8rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }


  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 25px;
  }

  .logoUp {
    width: 40px;
    height: 40px;
    margin-top: -5px;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: none;
  }

  .connect2-2-Mob {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .bg1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/lightning.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    height: max-content;
    animation: left-to-right 1s ease-out forwards;
    opacity: 0;

    /* Set opacity to 0 to start with a fade-in effect */
  }

  @keyframes left-to-right {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }


  .bg2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/fire.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    height: max-content;
    animation: right-to-left 1s ease-out forwards;
    opacity: 0;
  }

  @keyframes right-to-left {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }




  .btn1 {
    width: 300px;
    font-size: 30px;
    background: rgb(33, 150, 0);
    background: linear-gradient(93deg, rgba(33, 150, 0, 1) 0%, rgba(22, 254, 229, 1) 100%);
    border: none;
    color: white;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
  }

  .btn2 {
    width: 300px;
    font-size: 30px;
    color: white;
    background: rgb(255, 86, 0);
    background: linear-gradient(94deg, rgba(255, 86, 0, 1) 0%, rgba(33, 150, 0, 1) 100%);
    border: none;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
  }

  .btn1:hover {
    transform: scale(1.2);
  }

  .btn2:hover {
    transform: scale(1.2);
  }

  .mainDiv {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/mainBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    display: flex;
    flex-flow: column nowrap;
  }

  .navBarStake {
    width: 100%;
    display: flex;
  }

  .formMain {
    display: none;
  }

  .formMain-2 {
    display: none;
    margin-bottom: 5%;
    margin-top: 5%;
  }

  .formMain2 {
    display: none;
    margin-bottom: 5%;
    margin-top: 5%;
    background-color: rgba(0, 0, 0, 0.534);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
  }

  .formMain2-2 {
    display: none;
    margin-bottom: 5%;
    background-color: rgba(0, 0, 0, 0.534);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
  }

  .battleBtn {
    display: none;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    font-size: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    border: none;
    background-color: black;
    color: white;
    border-radius: 20px;
    border: 2px white solid;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: 1px ease-in-out;
  }

  .battleBtn:hover {
    background-color: #000000b0;
  }

  .battleBtn-2 {
    display: none;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    font-size: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    border: none;
    background-color: black;
    color: white;
    border-radius: 20px;
    border: 2px white solid;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: 1px ease-in-out;
  }

  .battleBtn-2:hover {
    background-color: #000000b0;
  }


  .battle {
    width: 40px;
    height: 40px;
    margin-left: 10px;
  }

  .relicT {
    color: white;
    font-size: 30px;
  }

  .inputSection {
    color: white;
  }

  input {
    margin-top: 5%;
    margin-bottom: 5%;
    background-color: #3b3b3b5b;
    border: none;
    color: white;
    padding: 10px;
    border: 2px solid white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .inputSection {
    background-color: #009218;
    width: fit-content;
    border: none;
    font-size: 25px;
    box-shadow: -3px 3px #006811;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 3%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25%;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .images_show {
    width: 90%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .tS {
    text-transform: capitalize;
    text-align: center;
    color: white;
    font-size: 25px;
    border: none;
    color: white;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
    backdrop-filter: blur(5px);
    background: rgb(23, 208, 10);
    background: linear-gradient(317deg, rgba(23, 208, 10, 0.23771227240896353) 0%, rgba(253, 187, 45, 0.18168986344537819) 100%);
  }

  .notification {
    text-align: center;
    color: rgb(245, 209, 154);
    border: none;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
    backdrop-filter: blur(5px);
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .notification1 {
    font-size: 25px;
  }


  .buy {
    text-align: center;
    color: rgb(255, 255, 255);
    text-decoration: underline;
    font-size: 20px;
    cursor: pointer;
    background-color: rgba(255, 228, 196, 0);
  }

  .buy:hover {
    color: #b9b9b9;
  }

  .progress-bar {
    position: relative;
    width: 90%;
    height: 30px;
    border-radius: 100px;
    background-color: #ddd;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }

  .team1-progress {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0%;
    background: rgb(0, 77, 255);
    background: linear-gradient(106deg, rgba(0, 77, 255, 1) 0%, rgba(76, 130, 255, 1) 100%);
    border-radius: 10px 0 0 10px;
  }

  .team2-progress {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 0%;
    background: rgb(246, 121, 97);
    background: linear-gradient(106deg, rgba(246, 121, 97, 1) 0%, rgba(200, 33, 0, 1) 100%);
    border-radius: 0 10px 10px 0;
  }

  .teams {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10vh;

  }

  .teams2 {
    background-color: rgba(0, 0, 0, 0.582);
    padding: 5px;
    flex-flow: column nowrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    background: rgb(28, 94, 242);
    background: linear-gradient(106deg, rgba(28, 94, 242, 0.0761353567744909) 0%, rgba(218, 117, 22, 0.0648994017183111) 100%);
    backdrop-filter: blur(5px);
    border: 1px rgba(255, 255, 255, 0.37) solid;
  }

  .t1 {
    color: white;
    font-size: 30px;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgb(0, 102, 255), 0 -10px 20px rgb(0, 60, 150), 0 -18px 40px rgb(0, 127, 150);
  }

  .teams img {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .t2 {
    color: white;
    font-size: 30px;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #ee5650, 0 -10px 20px #e27a24, 0 -18px 40px #9c5113;
  }


  .vs {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 80px;
    color: #FFFFFF;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }

  .time1 {
    font-size: 25px;
    text-align: center;
    color: rgb(236, 236, 236);
    letter-spacing: 12px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .time2 {
    font-size: 60px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .bell {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }


  .cliamRewards {
    font-size: 25px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background: rgb(0, 198, 34);
    background: linear-gradient(4deg, rgba(0, 198, 34, 1) 0%, rgba(43, 147, 11, 1) 100%);
    border: none;
    color: white;
    padding: 10px;
    box-shadow: -5px 5px rgb(33, 83, 0);
  }

  .cliamRewards:hover {
    background: rgb(43, 147, 11);
    background: linear-gradient(4deg, rgba(43, 147, 11, 1) 0%, rgba(0, 198, 34, 1) 100%);
  }

  .congratz {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 25px;
    text-align: center;
    color: rgb(236, 236, 236);
    letter-spacing: 12px;
    text-shadow: 0 0 10px #FFFFFF;
    margin-bottom: 20px;
  }

  .popper {
    width: 50px;
    height: 50px;
  }

  .blur {
    backdrop-filter: blur(5px);
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .connect2-2-Mob {
    display: none;
  }
 
  .connect2-2-Mob-2{
    display: none;
  }
  
  .already {
    color: rgb(216, 255, 107);
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .loadingText{
    font-size: 16px;
    color: rgb(255, 255, 255);
    text-align: center;
  }
  .notificationMain {
    animation: glow 2s infinite;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
    background-color: #070a08af;
    width: fit-content;
    padding: 5px;

    padding-left: 20px;
    padding-right: 20px;
    border-radius: 50px;

    border: 2px solid #00aaff;
    /* Set your border style and color */
    box-shadow: 0 0 10px #00aaff;
  }

  .connect2-2-Mob {
    display: none;
  }

  .special4 {
    font-size: 16px;
    color: rgb(252, 208, 89);
    text-decoration: underline;
  }

  .success-message {
    color: #ffffff;
    border-radius: 5px;
    text-align: center;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 5%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
    transition: transform .2s ease-in-out;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: #e15764;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }

  .check {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  .alreadyMain {
    background-color: rgba(0, 0, 0, 0.445);
    margin-top: 2%;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100vw;
    height: fit-content;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMainImgSet {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-items: center;
    display: flex;
  }

  .navbar {
    width: 40px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    align-items: center;

  }

  #fontSize2 {
    font-size: 16px;
    color: white;
    text-align: left;
  }

  #fontSize2:hover {
    color: #818181;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: rgba(255, 255, 255, 0.233) 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgba(49, 49, 49, 0.603) solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .allWrap {
    background: rgb(70, 63, 101);
    background: radial-gradient(circle, rgba(70, 63, 101, 1) 0%, rgba(18, 19, 38, 1) 46%);
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /*contain: content;*/
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/bg2.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding-top: 10vh;
    padding-bottom: 10vh;
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    display: flex;
  }

  .footerImg {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: transform .2s;
  }

  .footerImg img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    height: 5vh;
  }

  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  .connect div:hover {
    transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
    width: 33.33%;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 18px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

  }

  #fontSize-2 {
    font-size: 25px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
  }

  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
      #009218;
    /* background color */
    color: #0000;
    border: none;
    transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
    text-shadow: calc(var(--_i, -1) * 0.08em) -.01em 0 var(--c),
      calc(var(--_i, -1) * -0.08em) .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }

  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }

  .button-92 {
    font-weight: bold;
    font-size: 1.8rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 35px;
  }

  .logoUp {
    width: 50px;
    height: 50px;
    margin-top: -5px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .bg1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/lightning.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    height: max-content;
    animation: left-to-right 1s ease-out forwards;
    opacity: 0;

    /* Set opacity to 0 to start with a fade-in effect */
  }

  @keyframes left-to-right {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }


  .bg2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/fire.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    animation: right-to-left 1s ease-out forwards;
    opacity: 0;
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    height: max-content;
  }

  @keyframes right-to-left {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }




  .btn1 {
    width: 380px;
    font-size: 40px;
    background: rgb(33, 150, 0);
    background: linear-gradient(93deg, rgba(33, 150, 0, 1) 0%, rgba(22, 254, 229, 1) 100%);
    border: none;
    color: white;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
  }

  .btn2 {
    width: 380px;
    font-size: 40px;
    color: white;
    background: rgb(255, 86, 0);
    background: linear-gradient(94deg, rgba(255, 86, 0, 1) 0%, rgba(33, 150, 0, 1) 100%);
    border: none;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
  }

  .btn1:hover {
    transform: scale(1.2);
  }

  .btn2:hover {
    transform: scale(1.2);
  }

  .mainDiv {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/mainBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    display: flex;
    flex-flow: column nowrap;
  }

  .navBarStake {
    width: 100%;
    display: flex;
  }

  .formMain {
    display: none;
  }

  .formMain-2 {
    display: none;
    margin-bottom: 5%;
    margin-top: 5%;
  }

  .formMain2 {
    display: none;
    margin-bottom: 5%;
    margin-top: 5%;
    background-color: rgba(0, 0, 0, 0.534);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
  }

  .formMain2-2 {
    display: none;
    margin-bottom: 5%;
    background-color: rgba(0, 0, 0, 0.534);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
  }

  .battleBtn {
    display: none;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    font-size: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    border: none;
    background-color: black;
    color: white;
    border-radius: 20px;
    border: 2px white solid;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: 1px ease-in-out;
  }

  .battleBtn:hover {
    background-color: #000000b0;
  }

  .battleBtn-2 {
    display: none;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    font-size: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    border: none;
    background-color: black;
    color: white;
    border-radius: 20px;
    border: 2px white solid;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: 1px ease-in-out;
  }

  .battleBtn-2:hover {
    background-color: #000000b0;
  }


  .battle {
    width: 40px;
    height: 40px;
    margin-left: 10px;
  }

  .relicT {
    color: white;
    font-size: 30px;
  }

  .inputSection {
    color: white;
  }

  input {
    margin-top: 5%;
    margin-bottom: 5%;
    background-color: #3b3b3b5b;
    border: none;
    color: white;
    padding: 10px;
    border: 2px solid white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .inputSection {
    background-color: #009218;
    width: fit-content;
    border: none;
    font-size: 25px;
    box-shadow: -3px 3px #006811;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 3%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20%;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .images_show {
    width: 90%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .tS {
    text-transform: capitalize;
    text-align: center;
    color: white;
    font-size: 28px;
    border: none;
    color: white;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
    backdrop-filter: blur(5px);
    background: rgb(23, 208, 10);
    background: linear-gradient(317deg, rgba(23, 208, 10, 0.23771227240896353) 0%, rgba(253, 187, 45, 0.18168986344537819) 100%);
  }

  .notification {
    text-align: center;
    color: rgb(245, 209, 154);
    border: none;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
    backdrop-filter: blur(5px);
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .notification1 {
    font-size: 25px;
  }


  .buy {
    text-align: center;
    color: rgb(255, 255, 255);
    text-decoration: underline;
    font-size: 20px;
    cursor: pointer;
    background-color: rgba(255, 228, 196, 0);
  }

  .buy:hover {
    color: #b9b9b9;
  }

  .progress-bar {
    position: relative;
    width: 90%;
    height: 30px;
    border-radius: 100px;
    background-color: #ddd;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }

  .team1-progress {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0%;
    background: rgb(0, 77, 255);
    background: linear-gradient(106deg, rgba(0, 77, 255, 1) 0%, rgba(76, 130, 255, 1) 100%);
    border-radius: 10px 0 0 10px;
  }

  .team2-progress {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 0%;
    background: rgb(246, 121, 97);
    background: linear-gradient(106deg, rgba(246, 121, 97, 1) 0%, rgba(200, 33, 0, 1) 100%);
    border-radius: 0 10px 10px 0;
  }

  .teams {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10vh;

  }

  .teams2 {
    background-color: rgba(0, 0, 0, 0.582);
    padding: 5px;
    flex-flow: column nowrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    background: rgb(28, 94, 242);
    background: linear-gradient(106deg, rgba(28, 94, 242, 0.0761353567744909) 0%, rgba(218, 117, 22, 0.0648994017183111) 100%);
    backdrop-filter: blur(5px);
    border: 1px rgba(255, 255, 255, 0.37) solid;
  }

  .t1 {
    color: white;
    font-size: 40px;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgb(0, 102, 255), 0 -10px 20px rgb(0, 60, 150), 0 -18px 40px rgb(0, 127, 150);
  }

  .teams img {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .t2 {
    color: white;
    font-size: 40px;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #ee5650, 0 -10px 20px #e27a24, 0 -18px 40px #9c5113;
  }


  .vs {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 80px;
    color: #FFFFFF;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }

  .time1 {
    font-size: 25px;
    text-align: center;
    color: rgb(236, 236, 236);
    padding-bottom: 30px;
    letter-spacing: 12px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .time2 {
    font-size: 60px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .bell {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }


  .cliamRewards {
    font-size: 25px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background: rgb(0, 198, 34);
    background: linear-gradient(4deg, rgba(0, 198, 34, 1) 0%, rgba(43, 147, 11, 1) 100%);
    border: none;
    color: white;
    padding: 10px;
    box-shadow: -5px 5px rgb(33, 83, 0);
  }

  .cliamRewards:hover {
    background: rgb(43, 147, 11);
    background: linear-gradient(4deg, rgba(43, 147, 11, 1) 0%, rgba(0, 198, 34, 1) 100%);
  }

  .congratz {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 25px;
    text-align: center;
    color: rgb(236, 236, 236);
    letter-spacing: 12px;
    text-shadow: 0 0 10px #FFFFFF;
    margin-bottom: 20px;
  }

  .popper {
    width: 50px;
    height: 50px;
  }

  .blur {
    backdrop-filter: blur(5px);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .connect2-2-Mob {
    display: none;
  }
  .connect2-2-Mob {
    display: none;
  }
  .connect2-2-Mob-2{
    display: none;
  }
  .loadingText{
    font-size: 16px;
    color: rgb(255, 255, 255);
    text-align: center;
  }
  .special4 {
    font-size: 16px;
    color: rgb(252, 208, 89);
    text-decoration: underline;
  }

  .notificationMain {
    animation: glow 2s infinite;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
    background-color: #070a08af;
    width: fit-content;
    padding: 5px;
    animation: glow 2s infinite;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 50px;

    border: 2px solid #00aaff;
    /* Set your border style and color */
    box-shadow: 0 0 10px #00aaff;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 5%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
    transition: transform .2s ease-in-out;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: #e15764;
  }

  .connectMob {
    display: none;
  }

  .success-message {
    color: #ffffff;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }

  .check {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  .already {
    color: rgb(216, 255, 107);
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .alreadyMain {
    background-color: rgba(0, 0, 0, 0.445);
    margin-top: 2%;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background: rgb(70, 63, 101);
    background: radial-gradient(circle, rgba(70, 63, 101, 1) 0%, rgba(18, 19, 38, 1) 46%);
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /*contain: content;*/
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/bg2.png') no-repeat fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding-top: 10vh;
    padding-bottom: 10vh;
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    display: flex;
  }

  .footerImg {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: transform .2s;
  }

  .footerImg img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    height: 5vh;
  }

  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  .connect div:hover {
    transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 18px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

  }

  #fontSize-2 {
    font-size: 25px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
  }

  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
      #009218;
    /* background color */
    color: #0000;
    border: none;
    transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
    text-shadow: calc(var(--_i, -1) * 0.08em) -.01em 0 var(--c),
      calc(var(--_i, -1) * -0.08em) .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }

  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }

  .button-92 {
    font-weight: bold;
    font-size: 1.8rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 35px;
  }

  .logoUp {
    width: 50px;
    height: 50px;
    margin-top: -8px;
  }


  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .bg1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/lightning.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    height: max-content;
    animation: left-to-right 1s ease-out forwards;
    opacity: 0;

    /* Set opacity to 0 to start with a fade-in effect */
  }

  @keyframes left-to-right {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }


  .bg2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/fire.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    animation: right-to-left 1s ease-out forwards;
    opacity: 0;
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    height: max-content;
  }

  @keyframes right-to-left {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }




  .btn1 {
    width: 500px;
    font-size: 55px;
    background: rgb(33, 150, 0);
    background: linear-gradient(93deg, rgba(33, 150, 0, 1) 0%, rgba(22, 254, 229, 1) 100%);
    border: none;
    color: white;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
  }

  .btn2 {
    width: 500px;
    font-size: 55px;
    color: white;
    background: rgb(255, 86, 0);
    background: linear-gradient(94deg, rgba(255, 86, 0, 1) 0%, rgba(33, 150, 0, 1) 100%);
    border: none;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
  }

  .btn1:hover {
    transform: scale(1.2);
  }

  .btn2:hover {
    transform: scale(1.2);
  }

  .mainDiv {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/mainBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    display: flex;
    flex-flow: column nowrap;
  }

  .navBarStake {
    width: 100%;
    display: flex;
  }

  .formMain {
    display: none;
  }

  .formMain-2 {
    display: none;
    margin-bottom: 5%;
    margin-top: 5%;
  }

  .formMain2 {
    display: none;
    margin-bottom: 5%;
    margin-top: 5%;
    background-color: rgba(0, 0, 0, 0.534);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
  }

  .formMain2-2 {
    display: none;
    margin-bottom: 5%;
    background-color: rgba(0, 0, 0, 0.534);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
  }

  .battleBtn {
    display: none;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    font-size: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    border: none;
    background-color: black;
    color: white;
    border-radius: 20px;
    border: 2px white solid;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: 1px ease-in-out;
  }

  .battleBtn:hover {
    background-color: #000000b0;
  }

  .battleBtn-2 {
    display: none;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    font-size: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    border: none;
    background-color: black;
    color: white;
    border-radius: 20px;
    border: 2px white solid;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: 1px ease-in-out;
  }

  .battleBtn-2:hover {
    background-color: #000000b0;
  }


  .battle {
    width: 40px;
    height: 40px;
    margin-left: 10px;
  }

  .relicT {
    color: white;
    font-size: 30px;
  }

  .inputSection {
    color: white;
  }

  input {
    margin-top: 5%;
    margin-bottom: 5%;
    background-color: #3b3b3b5b;
    border: none;
    color: white;
    padding: 10px;
    border: 2px solid white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .inputSection {
    background-color: #009218;
    width: fit-content;
    border: none;
    font-size: 25px;
    box-shadow: -3px 3px #006811;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 3%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }


  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .images_show {
    width: 90%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .tS {
    text-transform: capitalize;
    text-align: center;
    color: white;
    font-size: 28px;
    border: none;
    color: white;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
    backdrop-filter: blur(5px);
    background: rgb(23, 208, 10);
    background: linear-gradient(317deg, rgba(23, 208, 10, 0.23771227240896353) 0%, rgba(253, 187, 45, 0.18168986344537819) 100%);

  }

  .notification {
    text-align: center;
    color: rgb(245, 209, 154);
    border: none;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
    backdrop-filter: blur(5px);
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .notification1 {
    font-size: 25px;
  }


  .buy {
    text-align: center;
    color: rgb(255, 255, 255);
    text-decoration: underline;
    font-size: 20px;
    cursor: pointer;
    background-color: rgba(255, 228, 196, 0);
  }

  .buy:hover {
    color: #b9b9b9;
  }

  .progress-bar {
    position: relative;
    width: 90%;
    height: 30px;
    border-radius: 100px;
    background-color: #ddd;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }

  .team1-progress {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0%;
    background: rgb(0, 77, 255);
    background: linear-gradient(106deg, rgba(0, 77, 255, 1) 0%, rgba(76, 130, 255, 1) 100%);
    border-radius: 10px 0 0 10px;
  }

  .team2-progress {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 0%;
    background: rgb(246, 121, 97);
    background: linear-gradient(106deg, rgba(246, 121, 97, 1) 0%, rgba(200, 33, 0, 1) 100%);
    border-radius: 0 10px 10px 0;
  }

  .teams {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10vh;

  }

  .teams2 {
    background-color: rgba(0, 0, 0, 0.582);
    padding: 5px;
    flex-flow: column nowrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    background: rgb(28, 94, 242);
    background: linear-gradient(106deg, rgba(28, 94, 242, 0.0761353567744909) 0%, rgba(218, 117, 22, 0.0648994017183111) 100%);
    backdrop-filter: blur(5px);
    border: 1px rgba(255, 255, 255, 0.37) solid;
  }

  .t1 {
    color: white;
    font-size: 50px;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgb(0, 102, 255), 0 -10px 20px rgb(0, 60, 150), 0 -18px 40px rgb(0, 127, 150);
  }

  .teams img {
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .t2 {
    color: white;
    font-size: 50px;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #ee5650, 0 -10px 20px #e27a24, 0 -18px 40px #9c5113;
  }


  .vs {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 100px;
    color: #FFFFFF;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }

  .time1 {
    font-size: 25px;
    text-align: center;
    color: rgb(236, 236, 236);
    padding-bottom: 30px;
    letter-spacing: 12px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .time2 {
    font-size: 60px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .bell {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }


  .cliamRewards {
    font-size: 25px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background: rgb(0, 198, 34);
    background: linear-gradient(4deg, rgba(0, 198, 34, 1) 0%, rgba(43, 147, 11, 1) 100%);
    border: none;
    color: white;
    padding: 10px;
    box-shadow: -5px 5px rgb(33, 83, 0);
  }

  .cliamRewards:hover {
    background: rgb(43, 147, 11);
    background: linear-gradient(4deg, rgba(43, 147, 11, 1) 0%, rgba(0, 198, 34, 1) 100%);
  }

  .congratz {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 25px;
    text-align: center;
    color: rgb(236, 236, 236);
    letter-spacing: 12px;
    text-shadow: 0 0 10px #FFFFFF;
    margin-bottom: 20px;
  }

  .popper {
    width: 50px;
    height: 50px;
  }

  .blur {
    backdrop-filter: blur(5px);
  }
}

@media screen and (min-width: 1920px) {
  .connectMob {
    display: none;
  }
  .connect2-2-Mob-2{
    display: none;
  }
  .connect2-2-Mob {
    display: none;
  }
  .connect2-2-Mob {
    display: none;
  }

  .loadingText{
    font-size: 22px;
    color: rgb(255, 255, 255);
    text-align: center;
  }
  .notificationMain {
    animation: glow 2s infinite;
    animation: glow 2s infinite;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
    background-color: #070a08af;
    width: fit-content;
    padding: 10px;

    padding-left: 40px;
    padding-right: 40px;
    border-radius: 70px;

    border: 3px solid #00aaff;
    /* Set your border style and color */
    box-shadow: 0 0 15px #00aaff;
  }

  .special4 {
    font-size: 22px;
    color: rgb(252, 208, 89);
    text-decoration: underline;
  }

  .success-message {
    color: white;
    border-radius: 5px;
    text-align: center;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 5%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 50px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 200px;
    height: fit-content;
    cursor: pointer;
    border: none;
    transition: transform .2s ease-in-out;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: #e15764;
  }

  .success-message h2 {
    font-size: 34px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 24px;
    margin-bottom: 0;
  }

  .check {
    width: 150px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 60px;
  }

  .already {
    color: rgb(216, 255, 107);
    text-align: center;
    font-size: 24px;
    margin-bottom: 10px;
  }

  .alreadyMain {
    background-color: rgba(0, 0, 0, 0.445);
    margin-top: 2%;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border-radius: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background: rgb(70, 63, 101);
    background: radial-gradient(circle, rgba(70, 63, 101, 1) 0%, rgba(18, 19, 38, 1) 46%);
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /*contain: content;*/
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/bg2.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding-top: 10vh;
    padding-bottom: 10vh;
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    display: flex;
  }

  .footerImg {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: transform .2s;
  }

  .footerImg img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    height: 1vh;
  }

  .connect div {
    margin-left: 18px;
    margin-right: 18px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  .connect div:hover {
    transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 42px;
    height: 42px;
    margin-left: 15px;
    margin-right: 15px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 27px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

  }

  #fontSize-2 {
    font-size: 25px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
  }

  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
      #009218;
    /* background color */
    color: #0000;
    border: none;
    transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
    text-shadow: calc(var(--_i, -1) * 0.08em) -.01em 0 var(--c),
      calc(var(--_i, -1) * -0.08em) .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }

  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }

  .button-92 {
    font-weight: bold;
    font-size: 2.5rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 50px;
  }

  .logoUp {
    width: 70px;
    height: 70px;
    margin-top: -10px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .bg1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/lightning.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    height: max-content;
    animation: left-to-right 1s ease-out forwards;
    opacity: 0;

    /* Set opacity to 0 to start with a fade-in effect */
  }

  @keyframes left-to-right {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }


  .bg2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/fire.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    animation: right-to-left 1s ease-out forwards;
    opacity: 0;
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    height: max-content;
  }

  @keyframes right-to-left {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }




  .btn1 {
    width: 700px;
    font-size: 85px;
    background: rgb(33, 150, 0);
    background: linear-gradient(93deg, rgba(33, 150, 0, 1) 0%, rgba(22, 254, 229, 1) 100%);
    border: none;
    color: white;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
  }

  .btn2 {
    width: 700px;
    font-size: 85px;
    color: white;
    background: rgb(255, 86, 0);
    background: linear-gradient(94deg, rgba(255, 86, 0, 1) 0%, rgba(33, 150, 0, 1) 100%);
    border: none;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
  }

  .btn1:hover {
    transform: scale(1.2);
  }

  .btn2:hover {
    transform: scale(1.2);
  }

  .mainDiv {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/mainBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    display: flex;
    flex-flow: column nowrap;
  }

  .navBarStake {
    width: 100%;
    display: flex;
  }

  .formMain {
    display: none;

  }

  .formMain-2 {
    display: none;
    margin-bottom: 5%;
    margin-top: 5%;
  }

  .formMain2 {
    display: none;
    margin-bottom: 5%;
    margin-top: 5%;
    background-color: rgba(0, 0, 0, 0.534);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
  }

  .formMain2-2 {
    display: none;
    margin-bottom: 5%;
    background-color: rgba(0, 0, 0, 0.534);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
  }

  .battleBtn {
    display: none;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    font-size: 50px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    border: none;
    background-color: black;
    color: white;
    border-radius: 20px;
    border: 4px white solid;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: 1px ease-in-out;
  }

  .battleBtn:hover {
    background-color: #000000b0;
  }

  .battleBtn-2 {
    display: none;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    font-size: 50px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    border: none;
    background-color: black;
    color: white;
    border-radius: 20px;
    border: 4px white solid;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: 1px ease-in-out;
  }

  .battleBtn-2:hover {
    background-color: #000000b0;
  }


  .battle {
    width: 55px;
    height: 55px;
    margin-left: 20px;
  }

  .relicT {
    color: white;
    font-size: 50px;
  }

  .inputSection {
    color: white;
  }

  input {
    margin-top: 5%;
    margin-bottom: 5%;
    background-color: #3b3b3b5b;
    border: none;
    color: white;
    padding: 10px;
    border: 2px solid white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .inputSection {
    background-color: #009218;
    width: fit-content;
    border: none;
    font-size: 35px;
    box-shadow: -3px 3px #006811;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 3%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .images_show {
    width: 90%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .tS {
    text-transform: capitalize;
    text-align: center;
    color: white;
    font-size: 40px;
    border: none;
    color: white;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
    backdrop-filter: blur(5px);
    background: rgb(23, 208, 10);
    background: linear-gradient(317deg, rgba(23, 208, 10, 0.23771227240896353) 0%, rgba(253, 187, 45, 0.18168986344537819) 100%);
  }

  .notification {
    text-align: center;
    color: rgb(245, 209, 154);
    border: none;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
    backdrop-filter: blur(5px);
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .notification1 {
    font-size: 40px;
  }


  .buy {
    text-align: center;
    color: rgb(255, 255, 255);
    text-decoration: underline;
    font-size: 35px;
    cursor: pointer;
    background-color: rgba(255, 228, 196, 0);
  }

  .buy:hover {
    color: #b9b9b9;
  }

  .progress-bar {
    position: relative;
    width: 90%;
    height: 40px;
    border-radius: 100px;
    background-color: #ddd;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    margin-top: 3%;
  }

  .team1-progress {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0%;
    background: rgb(0, 77, 255);
    background: linear-gradient(106deg, rgba(0, 77, 255, 1) 0%, rgba(76, 130, 255, 1) 100%);
    border-radius: 10px 0 0 10px;
    font-size: 25px;
  }

  .team2-progress {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 0%;
    background: rgb(246, 121, 97);
    background: linear-gradient(106deg, rgba(246, 121, 97, 1) 0%, rgba(200, 33, 0, 1) 100%);
    border-radius: 0 10px 10px 0;
    font-size: 25px;
  }

  .teams {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10vh;

  }

  .teams2 {
    background-color: rgba(0, 0, 0, 0.582);
    padding: 7px;
    flex-flow: column nowrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    background: rgb(28, 94, 242);
    background: linear-gradient(106deg, rgba(28, 94, 242, 0.0761353567744909) 0%, rgba(218, 117, 22, 0.0648994017183111) 100%);
    backdrop-filter: blur(5px);
    border: 2px rgba(255, 255, 255, 0.37) solid;
  }

  .t1 {
    color: white;
    font-size: 75px;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgb(0, 102, 255), 0 -10px 20px rgb(0, 60, 150), 0 -18px 40px rgb(0, 127, 150);
  }

  .teams img {
    width: 140px;
    height: 140px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .t2 {
    color: white;
    font-size: 75px;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #ee5650, 0 -10px 20px #e27a24, 0 -18px 40px #9c5113;
  }


  .vs {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 130px;
    color: #FFFFFF;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }

  .time1 {
    font-size: 33px;
    text-align: center;
    color: rgb(236, 236, 236);
    padding-bottom: 30px;
    letter-spacing: 12px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .time2 {
    font-size: 60px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .bell {
    width: 75px;
    height: 75px;
    margin-right: 20px;
  }

  .cliamRewards {
    font-size: 35px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background: rgb(0, 198, 34);
    background: linear-gradient(4deg, rgba(0, 198, 34, 1) 0%, rgba(43, 147, 11, 1) 100%);
    border: none;
    color: white;
    padding: 10px;
    box-shadow: -5px 5px rgb(33, 83, 0);
  }

  .cliamRewards:hover {
    background: rgb(43, 147, 11);
    background: linear-gradient(4deg, rgba(43, 147, 11, 1) 0%, rgba(0, 198, 34, 1) 100%);
  }

  .congratz {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 45px;
    text-align: center;
    color: rgb(236, 236, 236);
    letter-spacing: 12px;
    text-shadow: 0 0 10px #FFFFFF;
    margin-bottom: 20px;
  }

  .popper {
    width: 70px;
    height: 70px;
  }

  .blur {
    backdrop-filter: blur(5px);
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .connectMob {
    display: none;
  }
  .connect2-2-Mob {
    display: none;
  }
  .connect2-2-Mob-2{
    display: none;
  }
  .loadingText{
    font-size: 28px;
    color: rgb(255, 255, 255);
    text-align: center;
  }
  .notificationMain {
    animation: glow 2s infinite;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
    background-color: #070a08af;
    width: fit-content;
    padding: 10px;

    padding-left: 40px;
    padding-right: 40px;
    border-radius: 70px;

    border: 3px solid #00aaff;
    /* Set your border style and color */
    box-shadow: 0 0 15px #00aaff;
  }

  .connect2-2-Mob {
    display: none;
  }

  .special4 {
    font-size: 26px;
    color: rgb(252, 208, 89);
    text-decoration: underline;
  }

  .success-message {
    color: white;
    border-radius: 5px;
    text-align: center;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 5%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 70px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 300px;
    height: fit-content;
    cursor: pointer;
    border: none;
    transition: transform .2s ease-in-out;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: #e15764;
  }

  .success-message h2 {
    font-size: 44px;
    margin-bottom: 10px;
  }

  .success-message p {
    font-size: 34px;
    margin-bottom: 0;
  }

  .check {
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 60px;
  }

  .already {
    color: rgb(216, 255, 107);
    text-align: center;
    font-size: 30px;
    margin-bottom: 15px;
  }

  .alreadyMain {
    background-color: rgba(0, 0, 0, 0.445);
    margin-top: 2%;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border-radius: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background: rgb(70, 63, 101);
    background: radial-gradient(circle, rgba(70, 63, 101, 1) 0%, rgba(18, 19, 38, 1) 46%);
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /*contain: content;*/
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/bg2.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding-top: 10vh;
    padding-bottom: 10vh;
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    display: flex;
  }

  .footerImg {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: transform .2s;
  }

  .footerImg img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    height: 1vh;
  }

  .connect div {
    margin-left: 30px;
    margin-right: 30px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  .connect div:hover {
    transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 55px;
    height: 55px;
    margin-left: 15px;
    margin-right: 15px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 35px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

  }

  #fontSize-2 {
    font-size: 25px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
  }

  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
      #009218;
    /* background color */
    color: #0000;
    border: none;
    transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
    text-shadow: calc(var(--_i, -1) * 0.08em) -.01em 0 var(--c),
      calc(var(--_i, -1) * -0.08em) .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }

  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }

  .button-92 {
    font-weight: bold;
    font-size: 3.5rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 70px;
  }

  .logoUp {
    width: 95px;
    height: 95px;
    margin-top: -10px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .bg1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/lightning.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    height: max-content;
    animation: left-to-right 1s ease-out forwards;
    opacity: 0;

    /* Set opacity to 0 to start with a fade-in effect */
  }

  @keyframes left-to-right {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }


  .bg2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/fire.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    animation: right-to-left 1s ease-out forwards;
    opacity: 0;
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    height: max-content;
  }

  @keyframes right-to-left {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }




  .btn1 {
    width: 850px;
    font-size: 100px;
    background: rgb(33, 150, 0);
    background: linear-gradient(93deg, rgba(33, 150, 0, 1) 0%, rgba(22, 254, 229, 1) 100%);
    border: none;
    color: white;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
  }

  .btn2 {
    width: 850px;
    font-size: 100px;
    color: white;
    background: rgb(255, 86, 0);
    background: linear-gradient(94deg, rgba(255, 86, 0, 1) 0%, rgba(33, 150, 0, 1) 100%);
    border: none;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
  }

  .btn1:hover {
    transform: scale(1.2);
  }

  .btn2:hover {
    transform: scale(1.2);
  }

  .mainDiv {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/mainBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    display: flex;
    flex-flow: column nowrap;
  }

  .navBarStake {
    width: 100%;
    display: flex;
  }

  .formMain {
    display: none;
  }

  .formMain-2 {
    display: none;
    margin-bottom: 5%;
    margin-top: 5%;
  }

  .formMain2 {
    display: none;
    margin-bottom: 5%;
    margin-top: 5%;
    background-color: rgba(0, 0, 0, 0.534);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
  }

  .formMain2-2 {
    display: none;
    margin-bottom: 5%;
    background-color: rgba(0, 0, 0, 0.534);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
  }

  .battleBtn {
    display: none;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    font-size: 80px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
    border: none;
    background-color: black;
    color: white;
    border-radius: 20px;
    border: 4px white solid;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: 1px ease-in-out;
  }

  .battleBtn:hover {
    background-color: #000000b0;
  }

  .battleBtn-2 {
    display: none;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    font-size: 80px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
    border: none;
    background-color: black;
    color: white;
    border-radius: 20px;
    border: 4px white solid;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: 1px ease-in-out;
  }

  .battleBtn-2:hover {
    background-color: #000000b0;
  }


  .battle {
    width: 55px;
    height: 55px;
    margin-left: 20px;
  }

  .relicT {
    color: white;
    font-size: 70px;
  }

  .inputSection {
    color: white;
  }

  input {
    margin-top: 5%;
    margin-bottom: 5%;
    background-color: #3b3b3b5b;
    border: none;
    color: white;
    padding: 10px;
    border: 2px solid white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .inputSection {
    background-color: #009218;
    width: fit-content;
    border: none;
    font-size: 45px;
    box-shadow: -6px 6px #006811;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 20px;
  }

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 3%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .images_show {
    width: 90%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 20px;
    border: none;
    width: fit-content;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    font-size: 25px;
  }

  .tS {
    text-transform: capitalize;
    text-align: center;
    color: white;
    font-size: 50px;
    border: none;
    color: white;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
    backdrop-filter: blur(5px);
    background: rgb(23, 208, 10);
    background: linear-gradient(317deg, rgba(23, 208, 10, 0.23771227240896353) 0%, rgba(253, 187, 45, 0.18168986344537819) 100%);
  }

  .notification {
    text-align: center;
    color: rgb(245, 209, 154);
    border: none;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
    backdrop-filter: blur(5px);
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .notification1 {
    font-size: 60px;
  }


  .buy {
    text-align: center;
    color: rgb(255, 255, 255);
    text-decoration: underline;
    font-size: 45px;
    cursor: pointer;
    background-color: rgba(255, 228, 196, 0);
  }

  .buy:hover {
    color: #b9b9b9;
  }

  .progress-bar {
    position: relative;
    width: 90%;
    height: 60px;
    border-radius: 100px;
    background-color: #ddd;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    margin-top: 3%;
  }

  .team1-progress {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0%;
    background: rgb(0, 77, 255);
    background: linear-gradient(106deg, rgba(0, 77, 255, 1) 0%, rgba(76, 130, 255, 1) 100%);
    border-radius: 10px 0 0 10px;
    font-size: 35px;
  }

  .team2-progress {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 0%;
    background: rgb(246, 121, 97);
    background: linear-gradient(106deg, rgba(246, 121, 97, 1) 0%, rgba(200, 33, 0, 1) 100%);
    border-radius: 0 10px 10px 0;
    font-size: 35px;
  }

  .teams {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10vh;

  }

  .teams2 {
    background-color: rgba(0, 0, 0, 0.582);
    padding: 7px;
    flex-flow: column nowrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    background: rgb(28, 94, 242);
    background: linear-gradient(106deg, rgba(28, 94, 242, 0.0761353567744909) 0%, rgba(218, 117, 22, 0.0648994017183111) 100%);
    backdrop-filter: blur(5px);
    border: 2px rgba(255, 255, 255, 0.37) solid;
  }

  .t1 {
    color: white;
    font-size: 100px;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgb(0, 102, 255), 0 -10px 20px rgb(0, 60, 150), 0 -18px 40px rgb(0, 127, 150);
  }

  .teams img {
    width: 180px;
    height: 180px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .t2 {
    color: white;
    font-size: 100px;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #ee5650, 0 -10px 20px #e27a24, 0 -18px 40px #9c5113;
  }


  .vs {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 160px;
    color: #FFFFFF;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }

  .time1 {
    font-size: 33px;
    text-align: center;
    color: rgb(236, 236, 236);
    padding-bottom: 30px;
    letter-spacing: 12px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .time2 {
    font-size: 60px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .bell {
    width: 95px;
    height: 95px;
    margin-right: 20px;
  }

  .cliamRewards {
    font-size: 55px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background: rgb(0, 198, 34);
    background: linear-gradient(4deg, rgba(0, 198, 34, 1) 0%, rgba(43, 147, 11, 1) 100%);
    border: none;
    color: white;
    padding: 10px;
    box-shadow: -5px 5px rgb(33, 83, 0);
  }

  .cliamRewards:hover {
    background: rgb(43, 147, 11);
    background: linear-gradient(4deg, rgba(43, 147, 11, 1) 0%, rgba(0, 198, 34, 1) 100%);
  }

  .congratz {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 60px;
    text-align: center;
    color: rgb(236, 236, 236);
    letter-spacing: 12px;
    text-shadow: 0 0 10px #FFFFFF;
    margin-bottom: 20px;
  }

  .popper {
    width: 80px;
    height: 80px;
  }

  .blur {
    backdrop-filter: blur(5px);
  }
}

@media screen and (min-width: 3840px) {
  .connectMob {
    display: none;
  }
  .connect2-2-Mob {
    display: none;
  }
  .connect2-2-Mob-2{
    display: none;
  }
  .loadingText{
    font-size: 32px;
    color: rgb(255, 255, 255);
    text-align: center;
  }
  .notificationMain {
    animation: glow 2s infinite;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
    background-color: #070a08af;
    width: fit-content;
    padding: 10px;

    padding-left: 40px;
    padding-right: 40px;
    border-radius: 70px;

    border: 3px solid #00aaff;
    /* Set your border style and color */
    box-shadow: 0 0 15px #00aaff;
  }

  .connect2-2-Mob {
    display: none;
  }

  .special4 {
    font-size: 32px;
    color: rgb(252, 208, 89);
    text-decoration: underline;
  }

  .success-message {
    color: white;
    border-radius: 5px;
    text-align: center;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 5%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 90px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 300px;
    height: fit-content;
    cursor: pointer;
    border: none;
    transition: transform .2s ease-in-out;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: #e15764;
  }

  .success-message h2 {
    font-size: 64px;
    margin-bottom: 10px;
  }

  .success-message p {
    font-size: 54px;
    margin-bottom: 0;
  }

  .check {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 60px;
  }

  .already {
    color: rgb(216, 255, 107);
    text-align: center;
    font-size: 50px;
    margin-bottom: 15px;
  }

  .alreadyMain {
    background-color: rgba(0, 0, 0, 0.445);
    margin-top: 2%;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border-radius: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background: rgb(70, 63, 101);
    background: radial-gradient(circle, rgba(70, 63, 101, 1) 0%, rgba(18, 19, 38, 1) 46%);
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /*contain: content;*/
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/bg2.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding-top: 10vh;
    padding-bottom: 10vh;
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    display: flex;
  }

  .footerImg {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: transform .2s;
  }

  .footerImg img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 40px;
    padding-top: 40px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 45px;
    margin-right: 45px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  .connect div:hover {
    transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 85px;
    height: 85px;
    margin-left: 25px;
    margin-right: 25px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 58px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

  }

  #fontSize-2 {
    font-size: 25px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
  }

  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
      #009218;
    /* background color */
    color: #0000;
    border: none;
    transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
    text-shadow: calc(var(--_i, -1) * 0.08em) -.01em 0 var(--c),
      calc(var(--_i, -1) * -0.08em) .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }

  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }

  .button-92 {
    font-weight: bold;
    font-size: 6rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 95px;

  }

  .logoUp {
    width: 130px;
    height: 130px;
    margin-top: -20px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .bg1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/lightning.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    height: max-content;
    animation: left-to-right 1s ease-out forwards;
    opacity: 0;

    /* Set opacity to 0 to start with a fade-in effect */
  }

  @keyframes left-to-right {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }


  .bg2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/fire.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    height: max-content;
    animation: right-to-left 1s ease-out forwards;
    opacity: 0;
  }

  @keyframes right-to-left {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }




  .btn1 {
    width: 850px;
    font-size: 100px;
    background: rgb(33, 150, 0);
    background: linear-gradient(93deg, rgba(33, 150, 0, 1) 0%, rgba(22, 254, 229, 1) 100%);
    border: none;
    color: white;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
  }

  .btn2 {
    width: 850px;
    font-size: 100px;
    color: white;
    background: rgb(255, 86, 0);
    background: linear-gradient(94deg, rgba(255, 86, 0, 1) 0%, rgba(33, 150, 0, 1) 100%);
    border: none;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
  }

  .btn1:hover {
    transform: scale(1.2);
  }

  .btn2:hover {
    transform: scale(1.2);
  }

  .mainDiv {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.0452364803699965) 0%, rgba(0, 0, 0, 0.2362477163250527) 50%, rgba(0, 0, 0, 0.7840005253138167) 100%), url('./assets/mainBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    display: flex;
    flex-flow: column nowrap;
  }

  .navBarStake {
    width: 100%;
    display: flex;
  }

  .formMain {
    display: none;
  }

  .formMain-2 {
    display: none;
    margin-bottom: 5%;
    margin-top: 5%;
  }

  .formMain2 {
    display: none;
    margin-bottom: 5%;
    margin-top: 5%;
    background-color: rgba(0, 0, 0, 0.534);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
  }

  .formMain2-2 {
    display: none;
    margin-bottom: 5%;
    background-color: rgba(0, 0, 0, 0.534);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
  }

  .battleBtn {
    display: none;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    font-size: 80px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
    border: none;
    background-color: black;
    color: white;
    border-radius: 20px;
    border: 4px white solid;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: 1px ease-in-out;
  }

  .battleBtn:hover {
    background-color: #000000b0;
  }

  .battleBtn-2 {
    display: none;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    font-size: 80px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
    border: none;
    background-color: black;
    color: white;
    border-radius: 20px;
    border: 4px white solid;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: 1px ease-in-out;
  }

  .battleBtn-2:hover {
    background-color: #000000b0;
  }


  .battle {
    width: 55px;
    height: 55px;
    margin-left: 20px;
  }

  .relicT {
    color: white;
    font-size: 70px;
  }

  .inputSection {
    color: white;
  }

  input {
    margin-top: 5%;
    margin-bottom: 5%;
    background-color: #3b3b3b5b;
    border: none;
    color: white;
    padding: 10px;
    border: 2px solid white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .inputSection {
    background-color: #009218;
    width: fit-content;
    border: none;
    font-size: 45px;
    box-shadow: -6px 6px #006811;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 20px;
  }

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 3%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .images_show {
    width: 90%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 30px;
    border: none;
    width: fit-content;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    font-size: 35px;
  }

  .tS {
    text-transform: capitalize;
    text-align: center;
    color: white;
    font-size: 60px;
    border: none;
    color: white;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
    backdrop-filter: blur(5px);
    background: rgb(23, 208, 10);
    background: linear-gradient(317deg, rgba(23, 208, 10, 0.23771227240896353) 0%, rgba(253, 187, 45, 0.18168986344537819) 100%);
  }

  .notification {
    text-align: center;
    color: rgb(245, 209, 154);
    border: none;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
    backdrop-filter: blur(5px);
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .notification1 {
    font-size: 60px;
  }


  .buy {
    text-align: center;
    color: rgb(255, 255, 255);
    text-decoration: underline;
    font-size: 45px;
    cursor: pointer;
    background-color: rgba(255, 228, 196, 0);
  }

  .buy:hover {
    color: #b9b9b9;
  }

  .progress-bar {
    position: relative;
    width: 90%;
    height: 60px;
    border-radius: 100px;
    background-color: #ddd;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    margin-top: 3%;
  }

  .team1-progress {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0%;
    background: rgb(0, 77, 255);
    background: linear-gradient(106deg, rgba(0, 77, 255, 1) 0%, rgba(76, 130, 255, 1) 100%);
    border-radius: 10px 0 0 10px;
    font-size: 35px;
  }

  .team2-progress {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 0%;
    background: rgb(246, 121, 97);
    background: linear-gradient(106deg, rgba(246, 121, 97, 1) 0%, rgba(200, 33, 0, 1) 100%);
    border-radius: 0 10px 10px 0;
    font-size: 35px;
  }

  .teams {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10vh;

  }

  .teams2 {
    background-color: rgba(0, 0, 0, 0.582);
    padding: 7px;
    flex-flow: column nowrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    background: rgb(28, 94, 242);
    background: linear-gradient(106deg, rgba(28, 94, 242, 0.0761353567744909) 0%, rgba(218, 117, 22, 0.0648994017183111) 100%);
    backdrop-filter: blur(5px);
    border: 2px rgba(255, 255, 255, 0.37) solid;
  }

  .t1 {
    color: white;
    font-size: 100px;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgb(0, 102, 255), 0 -10px 20px rgb(0, 60, 150), 0 -18px 40px rgb(0, 127, 150);
  }

  .teams img {
    width: 180px;
    height: 180px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .t2 {
    color: white;
    font-size: 100px;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #ee5650, 0 -10px 20px #e27a24, 0 -18px 40px #9c5113;
  }


  .vs {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 160px;
    color: #FFFFFF;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }

  .time1 {
    font-size: 33px;
    text-align: center;
    color: rgb(236, 236, 236);
    padding-bottom: 30px;
    letter-spacing: 12px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .time2 {
    font-size: 60px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .bell {
    width: 95px;
    height: 95px;
    margin-right: 20px;
  }

  .cliamRewards {
    font-size: 55px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background: rgb(0, 198, 34);
    background: linear-gradient(4deg, rgba(0, 198, 34, 1) 0%, rgba(43, 147, 11, 1) 100%);
    border: none;
    color: white;
    padding: 10px;
    box-shadow: -5px 5px rgb(33, 83, 0);
  }

  .cliamRewards:hover {
    background: rgb(43, 147, 11);
    background: linear-gradient(4deg, rgba(43, 147, 11, 1) 0%, rgba(0, 198, 34, 1) 100%);
  }

  .congratz {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 60px;
    text-align: center;
    color: rgb(236, 236, 236);
    letter-spacing: 12px;
    text-shadow: 0 0 10px #FFFFFF;
    margin-bottom: 20px;
  }

  .popper {
    width: 80px;
    height: 80px;
  }
}